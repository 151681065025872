<template>
	<div class="rechargePage" :class="!isMobile ? 'pcbox' : ''">
		<div class="title" v-if="!isMobile">
			<h2>{{ $t('pay.title') }}</h2>
		</div>
		<div class="rechargeTop">
			<div class="backBtn" @click="handleBack" v-if="isMobile">
				<van-icon name="arrow-left" size="24" />
			</div>
			<div class="mycoins">
				<h3>{{ $t('personal.myPlatCoin') }}</h3>
				<p>{{ platCoin }}</p>
			</div>
		</div>
		<div class="rechargeBox">
			<h4>{{ $t('pay.rechargeTitle') }}</h4>
			<div class="rechargeList">
				<ul>
					<template v-for="(item,index) in coinlist" :key="index">
						<!-- <li :class="{'active':item == activeVal}" @click="handleCoin(item)">₩ {{ item }}</li> -->
						<li class="rechargeItem" :class="{'active':item == activeVal}">
							<span class="tag" v-if="payStatus !== 1">{{ $t('pay.rechargeDiscount',{ num: rechargeNum }) }}</span>
							<van-image width="100" height="100" :src="require(`@/assets/images/common/coin_icon.png`)" />
							<p v-if="payStatus !== 1" class="desc"><i class="icoin"></i>{{ item }}<span class="num"> + <i class="icoin"></i>{{ (item*(rechargeNum-1)).toFixed(0) }}</span></p>
							<p v-if="payStatus !== 1" style="margin-top: 0.5rem;"><i class="icoin"></i>{{ (item*rechargeNum).toFixed(0) }}</p>
							<p v-else style="margin-top: 0.5rem;"><i class="icoin"></i>{{ item }}</p>
							<van-button style="margin-top: 1.25rem;" color="linear-gradient(to right, #FE454D, #FF6F57)" block round :loading="payLoading" @click="handleRecharge">
							₩ {{ item }}
							</van-button>
						</li>
					</template>
				</ul>
				<!-- <van-cell-group inset>
				  <van-field clearable type="digit" v-model="rechargeVal" readonly
					  :label="$t('pay.rechargeMoney')" 
					  :placeholder="$t('pay.rechargePlaceholder')"
					  :disabled="payLoading"
					   @update:model-value="inputChange"
				   >
					  <template #button>
						  <span style="color: #FE454D" v-if="payStatus !== 1">{{ $t('pay.rechargeDiscount',{ num: rechargeNum }) }}</span>
				       </template>
				 </van-field>
				</van-cell-group> -->
			</div>
			<!-- <div class="btnBox">
				<van-button color="linear-gradient(to right, #FE454D, #FF6F57)" block round :loading="payLoading" @click="handleRecharge">{{ $t('pay.payRecharge') }}</van-button>
			</div> -->
		</div>
		
		<!-- 登录 -->
		<Login :showLogin="openLogin" @loginSuccess="loginSuccess" />
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed, reactive, onUnmounted } from 'vue'
	import useUserStore from '@/store/modules/user'
	import useConfigStore from '@/store/modules/config'
	import Login from '@/components/login'
	import { getGmPay, getGmPayFrist } from "@/api/gm";
	const { proxy } = getCurrentInstance();
	const coinlist = ref([10000])
	const rechargeVal = ref(10000)
	const activeVal = ref(10000)
	const payLoading = ref(false)
	const openLogin = ref(false)
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	const userInfo = computed(() => {
		return useUserStore().userInfo
	})
	let goodInfo = reactive({//默认商品信息
		gameId: 1,
		// goodId: 1,
		// goodName: '平台币',
		// num: 1,
		// voucher: 0,
		// voucherType: 0,
		// totalAmount: 0,
		userId: 0,
		source: 1
	})
	const platCoin = ref(0)
	const payStatus = ref(1)
	const rechargeNum = ref(3)//首充3倍
	const init = () => {
		if(!useUserStore().userInfo.superUserId){
			openLogin.value = true
			return false
		}
		getGmPayFrist({ userId: useUserStore().userInfo.superUserId }).then((res) => {
			payStatus.value = res.data.isBuy//1非首次购买 0首次购买
			platCoin.value = res.data.platCoin
			if(payStatus.value === 0){
				rechargeVal.value = (activeVal.value * rechargeNum.value).toFixed(0)
			}
		})
	}
	const loginSuccess = () => {
		init()
		openLogin.value = false
	}
	const handleVisibilityChange = () => {
		if (document.visibilityState === 'visible'){
			init()
		}else{
			console.log('不可见')
		}
	}
	onMounted(() => {
		init()
		document.addEventListener('visibilitychange', handleVisibilityChange);
	})
	onUnmounted(() => {
		document.removeEventListener('visibilitychange', handleVisibilityChange);
	})
	const handleCoin = (val) => {
		if(payLoading.value){
			proxy.$toast('Loading...')
			return false
		}
		activeVal.value = val
		rechargeVal.value = val
	}
	const inputChange = (val) => {
		activeVal.value = Number(val)
	}
	const handleBack = () => {
		history.back()
	}
	const handleRecharge = () => {
		if(rechargeVal.value%100 != 0){
			proxy.$toast(proxy.$t('pay.tipsInputRecharge'))
			return false
		}
		if(!useUserStore().userToken|| !useUserStore().userInfo.superUserId){
			proxy.$toast(proxy.$t('personal.tipsLogin'))
			return false
		}
		// goodInfo.totalAmount = rechargeVal.value
		goodInfo.userId = parseInt(useUserStore().userInfo.superUserId)
		console.log(goodInfo)
		payLoading.value = true
		getGmPay(goodInfo).then((res) => {
			console.log(res)
			if(res.data && res.data.redirectUrl){
				window.open(res.data.redirectUrl,'_blank')
			}else{
				proxy.$toast(res.msg)
			}
			payLoading.value = false
		}).catch(() => {
			payLoading.value = false
		})
	}
</script>

<style lang="less" scoped>
	.rechargePage{
		position: relative;width: 100%;height: calc(100vh - 40px);overflow-y: scroll;
		background: #2B2C30;
		.rechargeTop{
			height: 16.875rem;width: 100%;
			background: url('~@/assets/images/common/platcoin_bg.png') no-repeat;
			background-size: 100%;
			background-position-x: 3.75rem;
			background-position-y: 1rem;
			color: #fff;position: relative;
			.mycoins{
				position: absolute;bottom: 3.125rem;left: 1rem;
				h3{
					font-weight: normal;font-size: 0.9375rem;margin-bottom: 0.375rem;
				}
				p{
					font-size: 2rem;font-weight: 500;
				}
			}
			.backBtn{
				position: absolute;left: 1rem;top: 1rem;
			}
		}
		.rechargeBox{
			background: #fff;width: 100%;height: calc(100% - 16.875rem);border-radius: 0.625rem 0.625rem 0 0;
			position: absolute;bottom: 0;left: 0;min-height: 15rem;;
			h4{
				padding: 0.8125rem 1rem 0;
			}
			.rechargeList{
				width: 100%;height: calc(100% - 100px);overflow-y: scroll;
			}
			ul{
				display: flex;flex-wrap: wrap;margin-bottom: 0.625rem;padding: 0.75rem 1rem;box-sizing: border-box;
				li{
					width: 49%;margin-right: 2%;text-align: center;margin-top: 0.75rem;
					box-sizing: border-box;padding: 2rem 0.625rem 1rem;border-radius: 0.5rem;
					background: #F0F1F1;color: #303133;font-weight: 500;
					border: 0.125rem solid #F0F1F1;
					&:nth-child(2n){
						margin-right: 0;
					}
					&.active{
						border: 0.125rem solid #FE4D4F;
						// background: #FFF0ED;
						background: #FFF;
					}
					&.rechargeItem{
						position: relative;
						
						.tag{
							display: inline-block;position: absolute;left: 0;top: 0;
							background: #FFF0ED;color: #FE4D4F;font-size: 0.75rem;
							padding: 0.1875rem 0.75rem;box-sizing: border-box;
							border-radius: 0.5rem 0 0.5rem;
						}
						p{
							display: flex;align-items: center;justify-content: center;
						}
						.icoin{
							display: inline-block;width: 1rem;height: 1rem;margin-right: 0.3125rem;
							background: url('~@/assets/images/common/coin_icon.png') no-repeat center;background-size: 100%;
						}
						.desc{
							margin-bottom: 0.75rem;font-size: 0.75rem;color: #999;margin-top: 0.3125rem;font-weight: 400;
							span.num{
								margin-left: 5px;
								display: inline-flex;align-items: center;
								.icoin{margin-left: 5px;}
							}
							.icoin{
								width: 0.75rem;height: 0.75rem;
							}
						}
					}
				}
			}
			.btnBox{
				padding: 0.75rem 1rem;position: absolute;left: 0;bottom: 0;width: 100%;box-sizing: border-box;
			}
			/deep/.van-cell-group{
				background: #F0F1F1;
				.van-cell{
					background: #F0F1F1;font-weight: 500;
				}
			}
		}
		&.pcbox{
			display: flex;align-items: flex-start;justify-content: center;
			max-width: 1060px;background: transparent;padding-top: 100px;height: calc(100vh - 260px);
			.title{
				position: absolute;left: 0;top: 0;line-height: 120px;
			}
			.rechargeTop{
				width: 220px;height: 560px;
				background-color: #2B2C30;
				background-image: url('~@/assets/images/common/platcoin_bg.png');
				background-repeat: no-repeat;
				background-size: 170%;
				background-position-x: 50%;
				background-position-y: -1.25rem;
				.mycoins{
					h3{
						font-size: 16px;
					}
					p{
						font-size: 30px;
					}
				}
			}
			.rechargeBox{
				width: 840px;height: 560px;position: relative;border-radius: 0;
				.btnBox{
					padding: 1.25rem 1rem;
				}
				ul{
					li{
						width: 31%;margin-right: 3.5%;
						&:nth-child(3n){
							margin-right: 0;
						}
					}
				}
			}
		}
	}
	
</style>